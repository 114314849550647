import React from 'react'
import { CKEditor } from 'ckeditor4-react'
import Paper from '@mui/material/Paper'

const Document = () => {
    return (
        <Paper elevation={3} sx={{ px: 2, py: 1 }}>
            <CKEditor
                type="inline"
                initData={
                    <p>
                        This is an example CKEditor 4 WYSIWYG editor instance.
                    </p>
                }
            />
        </Paper>
    )
}

export default Document
