import React from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    ListItem,
    ListItemText,
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import List from '@mui/material/List'

const HomeFavorites = () => {
    return (
        <Card>
            <CardHeader
                title={
                    <>
                        <StarIcon color="primary" />
                        Favorite elements
                    </>
                }
            />
            <CardContent>
                <List>
                    <ListItem>
                        <ListItemText>Document 1</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>Document 2</ListItemText>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    )
}

export default HomeFavorites
