import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { styled } from '@mui/material'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'
import SearchBar from './SearchBar'
import { Link as RouterButton } from 'react-router-dom'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Menu = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        sx={{
                            marginRight: 5,
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Knowlan
                    </Typography>
                    <SearchBar />
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent">
                <DrawerHeader>
                    <IconButton component={RouterButton} to="/">
                        <DashboardOutlinedIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem key="1" component={RouterButton} to="/folders">
                        <IconButton>
                            <FeedOutlinedIcon />
                        </IconButton>
                    </ListItem>
                    <ListItem key="2" component={RouterButton} to="/folders">
                        <IconButton>
                            <CheckCircleOutlinedIcon />
                        </IconButton>
                    </ListItem>
                    <ListItem key="3" component={RouterButton} to="/folders">
                        <IconButton>
                            <TranslateOutlinedIcon />
                        </IconButton>
                    </ListItem>
                    <ListItem key="4" component={RouterButton} to="/folders">
                        <IconButton>
                            <InsertLinkOutlinedIcon />
                        </IconButton>
                    </ListItem>
                </List>
            </Drawer>
        </Box>
    )
}

export default Menu
