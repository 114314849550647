import React from 'react'
import CustomBreadcrumbs from '../components/CustomBreadcrumbs'
import { Grid } from '@mui/material'
import FolderItem from '../components/FolderItem'

const Folders = () => {
    return (
        <div>
            <CustomBreadcrumbs />
            <Grid container spacing={2} mt={2}>
                <FolderItem />
                <FolderItem />
                <FolderItem />
                <FolderItem />
                <FolderItem />
            </Grid>
        </div>
    )
}

export default Folders
