import React from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import List from '@mui/material/List'
import StarIcon from '@mui/icons-material/Star'
import HomeFavorites from '../components/HomeFavorites'
import HomeLastet from '../components/HomeLatest'

const Document = () => {
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                    <HomeFavorites />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <HomeLastet />
                </Grid>
                <Grid item sm={4} xs={12}>
                    -
                </Grid>
            </Grid>
        </div>
    )
}

export default Document
