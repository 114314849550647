import React from 'react'
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    styled,
} from '@mui/material'
import FolderIcon from '@mui/icons-material/Folder'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import StarOutlineIcon from '@mui/icons-material/StarOutline'

const StyledCenteredItem = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
})

const FolderItem = () => {
    return (
        <Grid item alignContent="center" lg={3} sm={6} xs={12}>
            <Card>
                <CardHeader
                    action={
                        <StyledCenteredItem>
                            <IconButton>
                                <StarOutlineIcon />
                            </IconButton>
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        </StyledCenteredItem>
                    }
                    title={
                        <StyledCenteredItem>
                            <FolderIcon color="primary" sx={{ mr: 1 }} />
                            Folder name
                        </StyledCenteredItem>
                    }
                />
            </Card>
        </Grid>
    )
}

export default FolderItem
