import * as React from 'react'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined'

const actions = [
    { icon: <PostAddOutlinedIcon />, name: 'Document' },
    { icon: <AddTaskOutlinedIcon />, name: 'Task' },
]

const CustomSpeedDial = () => {
    return (
        <SpeedDial
            ariaLabel="Custom SpeedDial"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipOpen
                    tooltipTitle={action.name}
                />
            ))}
        </SpeedDial>
    )
}

export default CustomSpeedDial
