import React from 'react'
import { CssBaseline } from '@mui/material'
import Menu from './components/Menu'
import Box from '@mui/material/Box'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Document from './pages/Document'
import Folders from './pages/Folders'
import CustomSpeedDial from './components/CustomSpeedDial'
import { ThemeProvider } from '@mui/material/styles'
import { darkTheme, lightTheme } from './utils/theme'

function App() {
    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <BrowserRouter>
                <Menu />
                <Box
                    height="100%"
                    component="main"
                    sx={{
                        flexGrow: 1,
                        bgcolor: 'background.default',
                        pt: 12,
                        pl: 11,
                        pr: 4,
                    }}
                >
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/document" element={<Document />} />
                        <Route path="/folders" element={<Folders />} />
                    </Routes>
                </Box>
                <CustomSpeedDial />
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App
