// Define theme settings
import { createTheme } from '@mui/material'

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
})

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
})
